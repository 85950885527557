import { gsap, Power1, Power4, Elastic, Linear } from "gsap";
import CustomEase from "gsap/CustomEase";
import ScrollTrigger from "gsap/ScrollTrigger";
import ScrollTo from "gsap/ScrollToPlugin";
import inView from "in-view";

document.addEventListener('DOMContentLoaded',function() {

    gsap.registerPlugin(CustomEase, ScrollTrigger, ScrollTo);
    let gotoWelcomSectionFlag = true;

    const hero = function() {
        const ninja = gsap.timeline({
            onComplete: () => {
                setTimeout(()=>{
                    if(gotoWelcomSectionFlag)
                        gsap.to(window, {duration: 0.6, scrollTo:"#homeContent"});

                    },2000);
            }
        }).delay(1);
        ninja.
            from('.c-hero .o-ninja', {
                autoAlpha: 0,
                duration: 1,
                y: '-100%',
                ease: Elastic.easeOut.config(1, .8)
            })

        const ninjaShadow = gsap.timeline().delay(1);
        ninjaShadow.
            from('.o-ninja--shadow', {
                autoAlpha: 0,
                duration: 1,
                y: '-100%',
                ease: Elastic.easeOut.config(1, .8)
            }).
            from('.cube3d', {
                autoAlpha: 0,
                duration: 1,
                y: '-100%',
                ease: Elastic.easeOut.config(1, .8)
            }, '-=1')

        const block = gsap.timeline().delay(1);
        block.
            from('.c-hero .o-block', {
                autoAlpha: 0,
                duration: .2,
                x: '-100%',
                ease: Power1.easeOut
            })

        const blockDown = gsap.timeline().delay(1.2);
        blockDown.
            to('.c-hero .o-block', {
                duration: .15,
                y: 25,
            }).
            to('.c-hero .o-block', {
                duration: .35,
                y: 0,
            })

        const gradients = gsap.timeline().delay(1.2);
        gradients.
            from('.c-hero .o-gradient--2', {
                autoAlpha: 0,
                duration: 1.5,
                opacity: 0,
                ease: Power1.easeOut
            }).
            from('.c-hero .o-gradient--1a', {
                autoAlpha: 0,
                duration: 2.5,
                opacity: 0,
                ease: Power1.easeOut
            }, '-=.5').
            from('.c-hero .o-gradient--1b', {
                autoAlpha: 0,
                duration: 3.5,
                opacity: 0,
                ease: Power1.easeOut
            })

CustomEase.create("custom1", "M0,0 C0,0 0,0.152 0.014,0.408 0.038,0.849 1,1 1,1 ");;
CustomEase.create("custom2", "M0,0 C0,0 0,0.152 0.05,0.408 0.134,0.841 1,1 1,1 ");

        const cubeLeft01t = gsap.timeline().delay(1.2);
        const cubeLeft01 = document.querySelector('.o-cube--left01');
        cubeLeft01t.
            from(cubeLeft01, {
                duration: 4,
                ease: 'custom1',
                x: '400%',
                y: '200%',
                scale: 2
            }).
            from(cubeLeft01, {
                autoAlpha: 0,
                duration: .5,
            }, '-=4').
            from(cubeLeft01, {
                rotate: 1440,
                duration: 6,
            }, '-=4').

            to(cubeLeft01, {
                duration: 10,
                x: -100,
                y: -100
            }, '-=2')


/*
        const cubeLeft01rt = gsap.timeline({  repeat: -1 }).delay(6);
        cubeLeft01rt.
            to(cubeLeft01, {
                duration: 10,
                rotate: -360,
                ease:Linear.easeNone
            })
*/

        const cubeLeft02t = gsap.timeline().delay(1.2);
        const cubeLeft02 = document.querySelector('.o-cube--left02');
        cubeLeft02t.
            from(cubeLeft02, {
                duration: 4,
                ease: 'custom2',
                x: '400%',
                y: '200%',
                scale: 2
            }).
            from(cubeLeft02, {
                autoAlpha: 0,
                duration: .5,
            }, '-=4').
            from(cubeLeft02, {
                rotate: 1800,
                duration: 10,
            }, '-=4')

        const cubeLeft03t = gsap.timeline().delay(1.2);
        const cubeLeft03 = document.querySelector('.o-cube--left03');
        cubeLeft03t.
            from(cubeLeft03, {
                duration: 4,
                ease: 'custom1',
                x: '400%',
                y: '200%',
                scale: 2
            }).
            from(cubeLeft03, {
                autoAlpha: 0,
                duration: .5,
            }, '-=4').
            from(cubeLeft03, {
                rotate: 1440,
                duration: 6,
            }, '-=4').

            to(cubeLeft03, {
                duration: 10,
                x: -10,
                y: -10
            }, '-=2')


        const cubeRight01t = gsap.timeline().delay(1.2);
        const cubeRight01 = document.querySelector('.o-cube--right01');
        cubeRight01t.
            from(cubeRight01, {
                duration: 4,
                ease: 'custom2',
                x: '-200%',
                y: '100%',
                scale: 1.5
            }).
            from(cubeRight01, {
                autoAlpha: 0,
                duration: .5,
            }, '-=4').
            from(cubeRight01, {
                rotate: 1800,
                duration: 10,
            }, '-=4')

        const cubeRight02t = gsap.timeline().delay(1.2);
        const cubeRight02 = document.querySelector('.o-cube--right02');
        cubeRight02t.
            from(cubeRight02, {
                duration: 10,
                ease: 'custom1',
                x: '-800%',
                y: '600%',
                scale: 2
            }).
            from(cubeRight02, {
                autoAlpha: 0,
                duration: .5,
            }, '-=10').
            from(cubeRight02, {
                rotate: 1440,
                duration: 6,
            }, '-=10').

            to(cubeRight02, {
                duration: 10,
            }, '-=2')

        const cubeRight03t = gsap.timeline().delay(1.2);
        const cubeRight03 = document.querySelector('.o-cube--right03');
        cubeRight03t.
            from(cubeRight03, {
                duration: 10,
                ease: 'custom2',
                x: '-1600%',
                y: '600%',
                scale: 2
            }).
            from(cubeRight03, {
                autoAlpha: 0,
                duration: 0.2,
            }, '-=10').
            from(cubeRight03, {
                rotate: 1440,
                duration: 6,
            }, '-=10').

            to(cubeRight03, {
                duration: 10,
            }, '-=2')

        let rots = [
            { ry: 0,   rx: 0  }, // 1
            { ry: 90,  rx: 0  }, // 2
            { ry: 180, rx: 0  }, // 3
            { ry: 270, rx: 0  }, // 4
            { ry: 0,   rx: 90 }, // 5
            { ry: 0,   rx:-90 }  // 6
        ];

        gsap.set(".cube3d__face", { // apply transform rotations to each face of the cube
            rotateY: (i) => rots[i].ry,
            rotateX: (i) => rots[i].rx,
            z: -150,
        });

        gsap.timeline({ repeat: -1, defaults: { duration: 2, ease: Linear.easeNone } })
            .to(cube, { rotateX: 0,  rotateY:  -90  })
            .to(cube, { rotateX: 0,  rotateY:  -180 })
            .to(cube, { rotateX: -90,  rotateY:  -270 })
            .to(cube, { rotateX:-90, rotateY:  -360 })
            .to(cube, { rotateX:-90, rotateY:  -160 })
            .to(cube, { rotateX: 90, rotateY: -270 })
            .to(cube, { rotateX: 0,  rotateY: -360 })
/*
        const cubeLeft02 = gsap.timeline().delay(1.2);
        cubeLeft02.
            from('.c-hero .o-cube--left02', {
                duration: 4,
                ease: 'custom',
                x: '400%',
                y: '200%',
                scale: 2
            }).
            from('.c-hero .o-cube--left01', {
                autoAlpha: 0,
                duration: .5,
            }, '-=4').
            from('.c-hero .o-cube--left01', {
                rotate: 1440,
                duration: 6,
            }, '-=4').

            to('.c-hero .o-cube--left01', {
                duration: 10,
                x: -100,
                y: -100
            }, '-=2')
*/


/*
        const gradientsAnim = gsap.timeline({ yoyo: true, repeat: -1 }).delay(1);
        gradientsAnim.
            from('.c-hero .o-gradient--1a', {
                duration: .5,
                opacity: 1,
                ease: Power1.easeOut
            }).
            to('.c-hero .o-gradient--1a', {
                duration: 1.5,
                opacity: 0.8,
                ease: Power1.easeOut
            })
*/
    }

    const squirell = function() {
        const tl = gsap.timeline();

        tl.
            from('.c-hero__squirrel .squirell', {
                autoAlpha: 0,
                duration: 1,
                y: -500,
                ease: Elastic.easeOut.config(.8, .8)
            }).


            from('.c-hero__squirrel', {
                duration: 1,
                y: -40,
                ease: Elastic.easeOut.config(1, 0.3)
            }, '-=.8').

            from('.c-hero__squirrel .shadow', {
                autoAlpha: 0,
                duration: 1.7,
                scaleX: 0,
                y: -20,
                ease: Elastic.easeOut.config(.5, 1)
            }, '-=1.3')

/*
            from('.c-hero__squirrel .shadow', {
                y: -40,
                ease: Elastic.easeOut.config(1.4, 1)
            }, '-=1.6').
*/

    }

    const common = function() {
        gsap.utils.toArray(".js-fadeInChildren > *").forEach(function(section) {
            gsap.from(section, {
                scrollTrigger: {
                    trigger: section,
                    start: '-50px bottom',
                    toggleActions: "play complete complete reset",
                },
                opacity: 0,
                duration: .5,
                y: 50
            });
        });
    }


    const squirrelSection = function () {

        gotoWelcomSectionFlag = false;

        const squirrelBlue01t = gsap.timeline().delay(1.2);
        const squirrelBlue01 = document.querySelector('.c-asap-squirrel__blue1');
        squirrelBlue01t.from(squirrelBlue01, {
            duration: 2,
            ease: 'custom2',
            scale: 0
        }).from(squirrelBlue01, {
            autoAlpha: 0,
            duration: .5,
        }, '-=4').from(squirrelBlue01, {
            rotation: "+=360",
            ease: Linear.easeNone,
            duration: 6,
            repeat: -1
        }, '-=4')


        const squirrelBlue02t = gsap.timeline().delay(1.2);
        const squirrelBlue02 = document.querySelector('.c-asap-squirrel__blue2');
        squirrelBlue02t.from(squirrelBlue02, {
            duration: 2,
            ease: 'custom2',
            scale: 0
        }).from(squirrelBlue02, {
            autoAlpha: 0,
            duration: .5,
        }, '-=4').from(squirrelBlue02, {
            rotation: "+=360",
            ease: Linear.easeNone,
            duration: 10,
            repeat: -1
        }, '-=4')

        const squirrelOrange01t = gsap.timeline().delay(1.2);
        const squirrelOrange01 = document.querySelector('.c-asap-squirrel__orange');
        squirrelOrange01t.from(squirrelOrange01, {
            duration: 10,
            ease: 'custom2',
            scale: 0.1
        }).from(squirrelOrange01, {
            autoAlpha: 0,
            duration: 0.2,
        }, '-=10').from(squirrelOrange01, {
            rotation: "+=360",
            duration: 2,
            ease: Linear.easeNone,
            repeat: -1
        }, '-=10');


        document.querySelectorAll('.c-what-we-do [data-fade="left"]').forEach((el,index)=>{
           setTimeout(()=>el.classList.add('done'),index*100)
        });

        document.querySelectorAll('.c-what-we-do [data-fade="right"]').forEach((el,index)=>{
           setTimeout(()=>el.classList.add('done'),index*100)
        });


       setTimeout(()=>document.querySelector('.c-what-we-do [data-fade="up"]').classList.add('done'),300)


    }

    inView('#homeContent')
        .on('enter', squirrelSection).on('enter',function(el){
            setTimeout(()=>el.classList.add('in-view'),400);
    });

    window.anims = function() {
       // common();
        hero();
    }

}, false)
