(function() {

    const WebFont = require('webfontloader');

    WebFont.load({

        google: {
            families: ['Krona+One:400:latin-ext', 'Inter:500:latin-ext', 'Space+Grotesk:300,400:latin-ext']
        }
    });
})();

